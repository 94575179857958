import { CSSProperties, Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import * as dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { Button, ButtonLink, InvertedButton, LightColorButton, SubtleButton } from '../styles/ui/button'
import Paper from '../assets/images/paper.svg'
import Tablet from '../assets/images/tablet.svg'
import Scroll from '../assets/images/scroll.svg'
import Opus from '../assets/images/opus.svg'
import {
    AnlaIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    ExclamationPointIcon,
    Icon,
    PartyIcon,
    PenTipIcon,
    RoundedChevronDownIcon,
    RoundedChevronLeftIcon,
    RoundedChevronRightIcon,
    RoundedChevronUpIcon,
    RoundedPlusIcon,
    TierCheckIcon,
    TierDashIcon,
} from '../styles/ui/icons'
import { PaymentDialogPage, Session, SiteTheme } from '../globals/state'
import {
    getSubscriptionBindRequest,
    getSubscriptionChangeRequest,
    getSubscriptionRequest,
    GiftKeyType,
    ISubscriptionBindRequest,
    ISubscriptionBindResponse,
    ISubscriptionResponse,
} from '../data/request/request'
import {
    mobileSize,
    PaddleOpusID,
    PaddleScrollID,
    PaddleTabletID,
    PurchasesDisabled,
} from '../globals/constants'
import { mix, transparentize } from '../util/colour'
import { BanStatus, PaymentProcessorType, UserSubscription } from '../data/user/user'
import { logError } from '../util/browser'
import { Dark } from '../styles/themes/dark'
import { tierNumberToNameCaps } from '../util/subscription'
import { useWindowSize } from '../hooks/useWindowSize'
import { FlexCol, FlexColSpacer, FlexRow } from '../styles/ui/layout'
import { InfoText, InfoText2, WarningText } from '../styles/components/import/importlorebook'
import { getStorage } from '../data/storage/storage'
import { getUserSetting } from '../data/user/settings'
import { useAlreadyTriedPurchase } from '../hooks/useAlreadyTriedPurchase'
import {
    fillLocalization,
    splitFillLocalization,
    useLocalization,
    useLocalizedSubscriptionIdToName,
    useLocalizedTierNumberToName,
} from '../hooks/useLocalization'
import Spinner from './spinner'
import Modal, { ModalType } from './modals/modal'
import { UpdateNotifierBackground, UpdateNotifierOverlay } from './updatenotifier'
import useCapture from '../hooks/useCapture'
import { CloseButton } from './modals/common'
import { Survey } from './survey'
import useRememberedValue from '../hooks/useRememberedValue'
import { useAtom, useAtomValue } from 'jotai'

export const SubIcon = styled(Icon)<{ icon: string; color: string }>`
    height: 14px;
    mask-image: url(${(props) => props.icon});
    background-color: ${(props) => props.color};
    cursor: default;
    margin-top: 10px;
`

export const TierBenefitDesc = styled.div<{ color?: string }>`
    margin-bottom: 20px;
    position: relative;
    line-height: 20px;
    color: ${(props) => props.color ?? props.theme.colors.textMain};
    opacity: 0.8;
    font-size: 0.875rem;
`
export const TierBenefitName = styled.div<{ color?: string }>`
    font-weight: 600;
    font-family: ${(props) => props.theme.fonts.headings};
    color: ${(props) => props.color ?? props.theme.colors.textMain};
    line-height: 1.25rem;
    margin-bottom: 5px;
`
export const TierBlock = styled.div<{ color?: string }>`
    outline: 1px solid ${(props) => props.color ?? props.theme.colors.bg3};
    background: ${(props) => props.theme.colors.bg1};
    flex: 1 1 0;
    width: 100%;
    position: relative;
    max-width: 315px;
    margin-bottom: 44px;
    @media (max-width: ${mobileSize}) {
        margin-bottom: 54px;
    }
    padding-bottom: 50px;
`
export const TierName = styled.div<{ color: string }>`
    color: ${(props) => props.color};
    font-family: ${(props) => props.theme.fonts.headings};
    margin-top: 5px;
    font-size: 1.125rem;
    font-weight: 600;
`
export const TierBenefitSection = styled.div<{ border?: boolean }>`
    border-top: ${(props) => (props.border ? '1px' : '0')} solid #2b2d3f;
    padding: 20px 25px 10px;
`
export const TierBenefitSectionTitle = styled.div<{ color: string }>`
    color: ${(props) => props.color};
    font-weight: 700;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`
export const TierPrice = styled.div`
    line-height: 3.25rem;
    > span:first-child {
        font-size: 2.75rem;
        font-weight: 700;
    }

    > span:last-child {
        font-size: 1.375rem;
        font-weight: 400;
    }
`
export const TierValue = styled.div<{ color: string }>`
    position: absolute;
    width: 100%;
    bottom: -24px;
    left: 0;
    height: 40px;
    top: -41px;
    background-color: ${(props) => props.color};
    padding: 10px 30px;
    line-height: 20px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.bg1};
    outline: 1px solid ${(props) => props.color};
`
export const TierWow = styled.span<{ color: string }>`
    color: ${(props) => props.color};
    transform: rotate(14.44deg);
    position: absolute;
    top: -1rem;
    left: 5.8rem;
`
export const TierFinePrint = styled.div`
    color: ${(props) => props.color};
    font-size: 1.125rem;
    opacity: 0.7;
    @media (max-width: 900px) {
        font-size: 0.75rem;
    }
`

const SubscriptionDialog = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    gap: 10px;
    overflow-y: auto;
`
const SubscribeContainer = styled.div`
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
`
const SubscribeHeader = styled.h3`
    margin-top: 0;
`
const SubscribeText = styled.div`
    margin-bottom: 2rem;
`
const SubscribeButton = styled(Button)`
    border: 2px solid ${(props) => props.theme.colors.textHeadings} !important;
    color: ${(props) => props.theme.colors.textHeadings};
    font-weight: bold;
    padding: 0.6rem;
    justify-content: center;
`
const DontButton = styled(Button)`
    border: 2px solid transparent !important;
    color: ${(props) => props.theme.colors.textMain};
    padding: 0.6rem;
    justify-content: center;
`

const ChangeSubscriptionButton = styled(Button)`
    border: 2px solid transparent !important;
    color: ${(props) => props.theme.colors.textMain};
    padding: 0.6rem;
    justify-content: center;
`
const ChangeSubscriptionLink = styled(ButtonLink)`
    border: 2px solid transparent !important;
    color: ${(props) => props.theme.colors.textMain};
    padding: 0.6rem;
    justify-content: center;
`

export const SubscriptionTierList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    overflow-y: auto;
`
export const SubscriptionTier = styled.button<{ color: string; disabled: boolean }>`
    display: flex;
    user-select: false;
    flex-direction: column;
    background: none;
    align-items: center;
    cursor: pointer;
    flex: 1 1 30%;
    min-width: 200px;
    border: 0 !important;
    margin: 0;
    padding: 1px;
    padding-top: 41px;
    & div {
        color: ${(props) => props.theme.textMain};
    }

    ${(props) => (props.disabled ? 'opacity: 0.8;' : '')}

    ${Icon} {
        background-color: ${(props) => props.color};
    }
    > * {
        margin: 0;
        > * {
            text-align: left;
        }
        &:hover {
            background-color: ${(props) => mix(0.9, props.theme.colors.bg0, props.theme.colors.textMain)};
        }
    }
`

const SubscribeGiftKeyInput = styled.input`
    margin-bottom: 1rem;
`

export const SubscriptionInfo = styled.div`
    margin-top: 1rem;
    font-size: 0.875rem;
    opacity: 0.8;
`

const SubscriptionAboveInfo = styled.div`
    margin-bottom: 1rem;
    font-size: 1.1rem;
`

const LoadingSpinner = styled(Spinner)`
    width: 30px;
    height: 30px;
    align-self: center;
`

const ErrorInfo = styled.div`
    color: ${(props) => props.theme.colors.warning};
`

const BigIcon = styled(PartyIcon)`
    width: 50px;
    height: 50px;
    margin-top: 60px;
    margin-bottom: 40px;
    background: ${(props) => props.theme.colors.textHeadings};
    cursor: default;
`
const PurchasedTitle = styled.div`
    font-weight: 600;
    font-family: ${(props) => props.theme.fonts.headings};
    font-size: 1.375rem;
    color: ${(props) => props.theme.colors.textHeadings};
`

export function PaperTierContent(props: {
    style?: CSSProperties
    children: JSX.Element | JSX.Element[]
}): JSX.Element {
    const localization = useLocalization()
    const { width } = useWindowSize()
    const [collapsed, setCollapsed] = useState(false)
    useEffect(() => {
        if (width) {
            if (width > 1000 && collapsed) setCollapsed(false)
            else if (width <= 1000 && !collapsed) setCollapsed(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])
    return (
        <TierBlock style={props.style}>
            <TierBenefitSection>
                <SubIcon icon={Paper.src} color={'#D9FFE1'} />
                <TierName color={'#D9FFE1'}>{localization.tier.paper}</TierName>
                <TierPrice>
                    <span>{localization.subscription.tierCards.paperPrice}</span>
                    <span></span>
                </TierPrice>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle color={'#D9FFE1'}>
                    {localization.subscription.tierCards.titleMain}
                </TierBenefitSectionTitle>
                <TierBenefitName>{localization.subscription.tierCards.accessBest}</TierBenefitName>
                <TierBenefitDesc></TierBenefitDesc>
                <TierBenefitName>{localization.subscription.tierCards.freeGenerations}</TierBenefitName>
                <TierBenefitDesc></TierBenefitDesc>
                <TierBenefitName>
                    {fillLocalization(localization.subscription.tierCards.tokenMemory, 1024 * 6)}
                </TierBenefitName>
                <TierBenefitDesc>
                    {fillLocalization(localization.subscription.tierCards.tokenMemorySub, 1024 * 6 * 4)}
                </TierBenefitDesc>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle
                    color={'#D9FFE1'}
                    style={{ cursor: width <= 1000 ? 'pointer' : 'default' }}
                    onClick={(e) => {
                        if (width <= 1000) {
                            setCollapsed((c) => !c)
                            e.preventDefault()
                            e.stopPropagation()
                        }
                    }}
                >
                    {localization.subscription.tierCards.titleExtra}{' '}
                    {width <= 1000 ? (
                        collapsed ? (
                            <ArrowDownIcon style={{ opacity: 0.8, width: '12px' }} />
                        ) : (
                            <ArrowUpIcon style={{ opacity: 0.3, width: '12px' }} />
                        )
                    ) : null}
                </TierBenefitSectionTitle>
                {!collapsed && (
                    <Fragment>
                        <TierBenefitName>{localization.subscription.tierCards.freeTTS}</TierBenefitName>
                        <TierBenefitDesc>{localization.subscription.tierCards.TTSSub}</TierBenefitDesc>
                        <FlexColSpacer min={65} max={65} />
                    </Fragment>
                )}
            </TierBenefitSection>

            {props.children}
        </TierBlock>
    )
}
export function TabletTierContent(props: {
    style?: CSSProperties
    children?: JSX.Element | JSX.Element[]
}): JSX.Element {
    const localization = useLocalization()
    const { width } = useWindowSize()
    const [collapsed, setCollapsed] = useState(false)
    useEffect(() => {
        if (width) {
            if (width > 1000 && collapsed) setCollapsed(false)
            else if (width <= 1000 && !collapsed) setCollapsed(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])

    return (
        <TierBlock style={props.style}>
            <TierBenefitSection>
                <SubIcon icon={Tablet.src} color={Dark.colors.textHeadings} />
                <TierName color={Dark.colors.textHeadings}>{localization.tier.tablet}</TierName>
                <TierPrice>
                    <span>$10</span>
                    <span>{localization.subscription.tierCards.perMonth}</span>
                </TierPrice>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle color={Dark.colors.textHeadings}>
                    {localization.subscription.tierCards.imageGen}
                </TierBenefitSectionTitle>
                <TierBenefitName>NovelAI Diffusion Anime V3</TierBenefitName>
                <TierBenefitDesc>{localization.subscription.tierCards.animeDiffusionSub}</TierBenefitDesc>
                <TierBenefitName>NovelAI Diffusion Furry V3</TierBenefitName>
                <TierBenefitDesc>{localization.subscription.tierCards.furryDiffusionSub}</TierBenefitDesc>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle color={Dark.colors.textHeadings}>
                    {localization.subscription.tierCards.textGeneration}
                </TierBenefitSectionTitle>
                <TierBenefitName>Kayra 13b</TierBenefitName>
                <TierBenefitDesc>{localization.models.kayra.description}</TierBenefitDesc>
                <TierBenefitName>
                    {fillLocalization(localization.subscription.tierCards.tokenMemory, 1024 * 4)}
                </TierBenefitName>
                <TierBenefitDesc>
                    {fillLocalization(localization.subscription.tierCards.tokenMemorySub, 1024 * 4 * 4)}
                </TierBenefitDesc>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle
                    color={Dark.colors.textHeadings}
                    style={{ cursor: width <= 1000 ? 'pointer' : 'default' }}
                    onClick={(e) => {
                        if (width <= 1000) {
                            setCollapsed((c) => !c)
                            e.preventDefault()
                            e.stopPropagation()
                        }
                    }}
                >
                    {localization.subscription.tierCards.extraFeatures}{' '}
                    {width <= 1000 ? (
                        collapsed ? (
                            <ArrowDownIcon style={{ opacity: 0.8, width: '12px' }} />
                        ) : (
                            <ArrowUpIcon style={{ opacity: 0.3, width: '12px' }} />
                        )
                    ) : null}
                </TierBenefitSectionTitle>
                {!collapsed && (
                    <Fragment>
                        <TierBenefitName>
                            1000 <AnlaIcon style={{ display: 'inline-block', height: '1rem' }} />{' '}
                            {localization.general.anlas}
                        </TierBenefitName>
                        <TierBenefitDesc>{localization.subscription.tierCards.anlasSub}</TierBenefitDesc>
                        <TierBenefitName>{localization.subscription.tierCards.TTS}</TierBenefitName>
                        <TierBenefitDesc>{localization.subscription.tierCards.TTSSub}</TierBenefitDesc>
                        <FlexColSpacer min={45} max={45} />
                    </Fragment>
                )}
            </TierBenefitSection>

            {props.children}
        </TierBlock>
    )
}
export function ScrollTierContent(props: {
    style?: CSSProperties
    children?: JSX.Element | JSX.Element[]
}): JSX.Element {
    const localization = useLocalization()
    const { width } = useWindowSize()
    const [collapsed, setCollapsed] = useState(false)
    useEffect(() => {
        if (width) {
            if (width > 1000 && collapsed) setCollapsed(false)
            else if (width <= 1000 && !collapsed) setCollapsed(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])
    return (
        <TierBlock style={props.style} color={Dark.colors.textHeadingsOptions[1]}>
            <TierBenefitSection>
                <TierValue color={Dark.colors.textHeadingsOptions[1]}>Best Value</TierValue>
                <SubIcon icon={Scroll.src} color={Dark.colors.textHeadingsOptions[1]} />
                <TierName color={Dark.colors.textHeadingsOptions[1]}>{localization.tier.scroll}</TierName>
                <TierPrice>
                    <span>$15</span>
                    <span>{localization.subscription.tierCards.perMonth}</span>
                </TierPrice>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle color={Dark.colors.textHeadingsOptions[1]}>
                    {localization.subscription.tierCards.imageGen}
                </TierBenefitSectionTitle>
                <TierBenefitName>NovelAI Diffusion Anime V3</TierBenefitName>
                <TierBenefitDesc>{localization.subscription.tierCards.animeDiffusionSub}</TierBenefitDesc>
                <TierBenefitName>NovelAI Diffusion Furry V3</TierBenefitName>
                <TierBenefitDesc>{localization.subscription.tierCards.furryDiffusionSub}</TierBenefitDesc>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle color={Dark.colors.textHeadingsOptions[1]}>
                    {localization.subscription.tierCards.textGeneration}
                </TierBenefitSectionTitle>
                <TierBenefitName>Kayra 13b</TierBenefitName>
                <TierBenefitDesc>{localization.models.kayra.description}</TierBenefitDesc>
                <TierBenefitName>
                    {fillLocalization(localization.subscription.tierCards.tokenMemory, 1024 * 8)}
                </TierBenefitName>
                <TierBenefitDesc>
                    {fillLocalization(localization.subscription.tierCards.tokenMemorySub, 1024 * 8 * 4)}
                </TierBenefitDesc>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle
                    color={Dark.colors.textHeadingsOptions[1]}
                    style={{ cursor: width <= 1000 ? 'pointer' : 'default' }}
                    onClick={(e) => {
                        if (width <= 1000) {
                            setCollapsed((c) => !c)
                            e.preventDefault()
                            e.stopPropagation()
                        }
                    }}
                >
                    {localization.subscription.tierCards.extraFeatures}{' '}
                    {width <= 1000 ? (
                        collapsed ? (
                            <ArrowDownIcon style={{ opacity: 0.8, width: '12px' }} />
                        ) : (
                            <ArrowUpIcon style={{ opacity: 0.3, width: '12px' }} />
                        )
                    ) : null}
                </TierBenefitSectionTitle>
                {!collapsed && (
                    <Fragment>
                        <TierBenefitName>
                            1000 <AnlaIcon style={{ display: 'inline-block', height: '1rem' }} />{' '}
                            {localization.general.anlas}
                        </TierBenefitName>
                        <TierBenefitDesc>{localization.subscription.tierCards.anlasSub}</TierBenefitDesc>
                        <TierBenefitName>{localization.subscription.tierCards.TTS}</TierBenefitName>
                        <TierBenefitDesc>{localization.subscription.tierCards.TTSSub}</TierBenefitDesc>
                        <FlexColSpacer min={45} max={45} />
                    </Fragment>
                )}
            </TierBenefitSection>

            {props.children}
        </TierBlock>
    )
}
export function OpusTierContent(props: {
    style?: CSSProperties
    children?: JSX.Element | JSX.Element[]
}): JSX.Element {
    const localization = useLocalization()
    const { width } = useWindowSize()
    const [collapsed, setCollapsed] = useState(false)
    useEffect(() => {
        if (width) {
            if (width > 1000 && collapsed) setCollapsed(false)
            else if (width <= 1000 && !collapsed) setCollapsed(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])
    return (
        <TierBlock style={props.style} color={Dark.colors.textHeadingsOptions[3]}>
            <TierBenefitSection>
                <TierValue color={Dark.colors.textHeadingsOptions[3]}>For the Enthusiast</TierValue>
                <SubIcon icon={Opus.src} color={Dark.colors.textHeadingsOptions[3]} />
                <TierName color={Dark.colors.textHeadingsOptions[3]}>{localization.tier.opus}</TierName>
                <TierPrice>
                    <span>$25</span>
                    <span>{localization.subscription.tierCards.perMonth}</span>
                </TierPrice>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle color={Dark.colors.textHeadingsOptions[3]}>
                    {localization.subscription.tierCards.imageGen}
                </TierBenefitSectionTitle>
                <TierBenefitName>NovelAI Diffusion Anime V3</TierBenefitName>
                <TierBenefitDesc>{localization.subscription.tierCards.animeDiffusionSub}</TierBenefitDesc>
                <TierBenefitName>NovelAI Diffusion Furry V3</TierBenefitName>
                <TierBenefitDesc>{localization.subscription.tierCards.furryDiffusionSub}</TierBenefitDesc>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle color={Dark.colors.textHeadingsOptions[3]}>
                    {localization.subscription.tierCards.textGeneration}
                </TierBenefitSectionTitle>
                <TierBenefitName>Llama 3 Erato 70b</TierBenefitName>
                <TierBenefitDesc>{localization.models.erato.description}</TierBenefitDesc>
                <TierBenefitName>Kayra 13b</TierBenefitName>
                <TierBenefitDesc>{localization.models.kayra.description}</TierBenefitDesc>
                <TierBenefitName>
                    {fillLocalization(localization.subscription.tierCards.tokenMemory, 1024 * 8)}
                </TierBenefitName>
                <TierBenefitDesc>
                    {fillLocalization(localization.subscription.tierCards.tokenMemorySub, 1024 * 8 * 4)}
                </TierBenefitDesc>
            </TierBenefitSection>

            <TierBenefitSection border>
                <TierBenefitSectionTitle
                    color={Dark.colors.textHeadingsOptions[3]}
                    style={{ cursor: width <= 1000 ? 'pointer' : 'default' }}
                    onClick={(e) => {
                        if (width <= 1000) {
                            setCollapsed((c) => !c)
                            e.preventDefault()
                            e.stopPropagation()
                        }
                    }}
                >
                    {localization.subscription.tierCards.extraFeatures}{' '}
                    {width <= 1000 ? (
                        collapsed ? (
                            <ArrowDownIcon style={{ opacity: 0.8, width: '12px' }} />
                        ) : (
                            <ArrowUpIcon style={{ opacity: 0.3, width: '12px' }} />
                        )
                    ) : null}
                </TierBenefitSectionTitle>
                {!collapsed && (
                    <Fragment>
                        <TierBenefitName>
                            10,000 <AnlaIcon style={{ display: 'inline-block', height: '1rem' }} />{' '}
                            {localization.general.anlas}
                        </TierBenefitName>
                        <TierBenefitDesc>{localization.subscription.tierCards.anlasSub}</TierBenefitDesc>
                        <TierBenefitName>{localization.subscription.tierCards.TTS}</TierBenefitName>
                        <TierBenefitDesc>
                            {localization.subscription.tierCards.imageGenSubUnlimited}
                        </TierBenefitDesc>
                        <TierBenefitName>
                            {localization.subscription.tierCards.experimentalFeatures}
                        </TierBenefitName>
                        <TierBenefitDesc>
                            {localization.subscription.tierCards.experimentalFeaturesSub}
                        </TierBenefitDesc>
                    </Fragment>
                )}
            </TierBenefitSection>

            {props.children}
        </TierBlock>
    )
}

const purchase = (
    auth_token: string,
    product: number,
    success: (product: number) => void,
    close: (product: number) => void
) => {
    if (!(window as any).Paddle?.Checkout) {
        toast(
            // eslint-disable-next-line max-len
            'Payment provider failed to load. Please reload the page. Some regions cannot be served by Paddle, please check if that applies to you if this error persists.',
            { type: 'error' }
        )
        return
    }
    ;(window as any).Paddle.Checkout.open({
        product: product,
        passthrough: JSON.stringify({
            auth_token,
        }),
        successCallback: (data: any) => {
            if (data && data.checkout && data.checkout.id) {
                success(product)
            }
        },
        closeCallback: (data: any) => {
            close(product)
        },
    })
}

function SubscriptionPurchase(props: { onSuccess: () => void; explanation?: string }): JSX.Element {
    const { capture } = useCapture()
    const localization = useLocalization()
    const session = useAtomValue(Session)
    // disable purchase of sub when last attempted in the last 12 hrs
    const alreadyTriedPurchase = useAlreadyTriedPurchase(session.settings)
    const [buttonsEnabled, setButtonsEnabled] = useState(!PurchasesDisabled && !alreadyTriedPurchase)
    const theme = useAtomValue(SiteTheme)

    const paymentSuccess = (product: number) => {
        capture('subscription_purchase:succeded', {
            paymentProcessor: PaymentProcessorType.PADDLE,
            product,
            location: 'subscription',
        })
        setButtonsEnabled(false)
        props.onSuccess()
    }

    const paymentClose = (product: number) => {
        capture('subscription_purchase:closed', {
            paymentProcessor: PaymentProcessorType.PADDLE,
            product,
            location: 'subscription',
        })
    }

    return (
        <SubscriptionDialog>
            <SubscribeHeader>{localization.subscription.tierPricing}</SubscribeHeader>
            {props.explanation ? <SubscriptionAboveInfo>{props.explanation}</SubscriptionAboveInfo> : <></>}
            {PurchasesDisabled && (
                <WarningText
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left',
                        textAlign: 'left',
                        margin: 10,
                    }}
                >
                    {localization.subscription.checkoutDisabled}
                    <br />
                    {localization.subscription.subLongerActivate}
                </WarningText>
            )}
            {alreadyTriedPurchase && (
                <InfoText>
                    <FlexRow>
                        <ExclamationPointIcon />
                        <div style={{ width: 8 }} />
                        <FlexCol style={{ textAlign: 'left', gap: 8 }}>
                            <span>{localization.subscription.alreadyPurchased[0]}</span>
                            <span>
                                <b>{localization.subscription.alreadyPurchased[1]}</b>
                            </span>
                            <span>
                                {splitFillLocalization(
                                    localization.subscription.alreadyPurchased[2],
                                    'support@novelai.net'
                                ).map((part, i) => {
                                    switch (i) {
                                        case 1: {
                                            return (
                                                <a key={i} href={`mailto:${part}`}>
                                                    {part}
                                                </a>
                                            )
                                        }
                                        default: {
                                            return <span key={i}>{part}</span>
                                        }
                                    }
                                })}
                            </span>
                        </FlexCol>
                    </FlexRow>
                </InfoText>
            )}
            {!alreadyTriedPurchase && (
                <InfoText2 style={{ opacity: 0.8 }}>{localization.subscription.asiaPurchaseDelay}</InfoText2>
            )}
            <SubscribeContainer>
                <SubscriptionTierList>
                    <SubscriptionTier
                        color={theme.colors.textHeadingsOptions[0]}
                        onClick={() =>
                            purchase(session.auth_token, PaddleTabletID, paymentSuccess, paymentClose)
                        }
                        disabled={!buttonsEnabled}
                    >
                        <TabletTierContent />
                    </SubscriptionTier>
                    <SubscriptionTier
                        color={theme.colors.textHeadingsOptions[1]}
                        onClick={() =>
                            purchase(session.auth_token, PaddleScrollID, paymentSuccess, paymentClose)
                        }
                        disabled={!buttonsEnabled}
                    >
                        <ScrollTierContent />
                    </SubscriptionTier>
                    <SubscriptionTier
                        color={theme.colors.textHeadingsOptions[3]}
                        onClick={() =>
                            purchase(session.auth_token, PaddleOpusID, paymentSuccess, paymentClose)
                        }
                        disabled={!buttonsEnabled}
                    >
                        <OpusTierContent />
                    </SubscriptionTier>
                    <TierFinePrint>{localization.subscription.tierCards.finePrintImage}</TierFinePrint>
                </SubscriptionTierList>
            </SubscribeContainer>
        </SubscriptionDialog>
    )
}

export default function Subscription(props: { actionBlocked: boolean; onClose: () => void }): JSX.Element {
    const { capture } = useCapture()
    const localization = useLocalization()
    const tierNumberToName = useLocalizedTierNumberToName()
    const [paymentDialog, setPaymentDialog] = useAtom(PaymentDialogPage)
    const [session, setSession] = useAtom(Session)
    const [error, setError] = useState('')
    const [giftKey, setGiftKey] = useState('')
    const [changeTier, setChangeTier] = useState(-1)
    const [checkTimeout, setCheckTimeout] = useState(0)
    const theme = useAtomValue(SiteTheme)
    const [unsubscribeModal, setUnsubscribeModal] = useState(false)

    function setSurveyDone(b: boolean) {
        setSession((session) => {
            const newSession = { ...session, settings: { ...session.settings, unsubSurveyShown: b } }
            const storage = getStorage(session)
            storage.saveSettings(newSession.settings)
            return newSession
        })
    }

    useEffect(() => {
        return () => {
            clearTimeout(checkTimeout)
        }
    })

    useEffect(() => {
        setError('')
        setPaymentDialog(0)
        getSubscriptionRequest(session.auth_token)
            .request()
            .then((response: ISubscriptionResponse) => {
                setSession({ ...session, subscription: response.subscription, priority: response.priority })
                if (!response.subscription.active) {
                    setPaymentDialog(2)
                } else {
                    setPaymentDialog(3)
                }
            })
            .catch((error) => {
                logError(error)
                setError(`${error}`)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeDialog = () => {
        props.onClose()
    }

    const onSuccess = () => {
        setError('')
        setPaymentDialog(0)
        setSession((session) => {
            const subscriptionPurchaseAttempt = Date.now()
            getStorage(session).saveSettings({
                ...session.settings,
                subscriptionPurchaseAttempt,
            })
            return {
                ...session,
                settings: {
                    ...session.settings,
                    subscriptionPurchaseAttempt,
                },
            }
        })
        let checks = 0
        const checkSubscription = () =>
            getSubscriptionRequest(session.auth_token)
                .request()
                .then((response: ISubscriptionResponse) => {
                    if (
                        !response.subscription.active ||
                        response.subscription.paymentProcessorData?.r ===
                            session.subscription.paymentProcessorData?.r
                    ) {
                        if (checks < 3) {
                            setCheckTimeout(setTimeout(checkSubscription, 2000) as any)
                            ++checks
                        } else if (checks < 12) {
                            setError(localization.subscription.paymentProcessing)
                            setCheckTimeout(setTimeout(checkSubscription, 2000) as any)
                        } else {
                            setError(localization.subscription.paymentProcessing)
                            setCheckTimeout(setTimeout(checkSubscription, 2000) as any)
                        }
                    } else {
                        setSession((session) => ({
                            ...session,
                            subscription: response.subscription,
                            priority: response.priority,
                        }))
                        setError('')
                        setPaymentDialog(3)
                    }
                })
                .catch((error) => {
                    logError(error)
                    setError(`${error}`)
                })
        setTimeout(checkSubscription, 2000)
    }

    const [giftKeyResponse, setGiftKeyResponse] = useState<ISubscriptionBindResponse | null>(null)
    const [showGiftKeyResult, setShowGiftKeyResult] = useState(false)
    const [redeemedStepsOnly, setRedeemedStepsOnly] = useState(false)

    const onGiftKey = (confirmOnlySteps: boolean = false, confirmReplaceSub: boolean = false) => {
        setError('')
        setPaymentDialog(0)
        let checks = 0
        const checkSubscription = (steps: boolean) =>
            getSubscriptionRequest(session.auth_token)
                .request()
                .then((response: ISubscriptionResponse) => {
                    if (!response.subscription.tier && !steps) {
                        if (checks < 3) {
                            setCheckTimeout(setTimeout(checkSubscription, 2000) as any)
                            ++checks
                        } else {
                            setError(localization.subscription.processingGiftKey)
                            setCheckTimeout(setTimeout(checkSubscription, 2000) as any)
                        }
                    } else {
                        capture('gift_key:redeemed', {
                            location: 'subscription',
                            giftKeyType: giftKeyResponse?.giftKeyType,
                        })
                        setSession({
                            ...session,
                            subscription: response.subscription,
                            priority: response.priority,
                        })
                        setPaymentDialog(4)
                        setShowGiftKeyResult(true)
                    }
                })
                .catch((error) => {
                    logError(error)
                    setError(`${error}`)
                })
        getSubscriptionBindRequest(
            session.auth_token,
            'giftkey',
            giftKey,
            confirmOnlySteps,
            confirmReplaceSub
        )
            .request()
            .then((response) => {
                setGiftKeyResponse(response)
                if (confirmOnlySteps) {
                    setRedeemedStepsOnly(true)
                }
                if (response.requiresConfirmation) {
                    // The key requires confirmation because the user is already subscribed.
                    if (
                        response.giftKeyType === 'sub' &&
                        session.subscription.paymentProcessorData?.t === PaymentProcessorType.PADDLE
                    ) {
                        // Paddle subscription and sub gift key, replace is not allowed.
                        setError(
                            // eslint-disable-next-line max-len
                            localization.subscription.cannotUseSubGiftKeyUnlessGifted
                        )
                        setGiftKey('')
                        setPaymentDialog(4)
                        return
                    }
                    setPaymentDialog(8)
                    return
                }
                setGiftKey('')
                setTimeout(
                    () => checkSubscription(response.giftKeyType === 'steps' || confirmOnlySteps),
                    2000
                )
            })
            .catch((error) => {
                if (error.status === 409) {
                    setError(localization.subscription.cannotSubGiftKey)
                    return
                }
                logError(error)
                setError(`${error}`)
                setPaymentDialog(4)
            })
    }

    const onSubscriptionChange = (tier: number) => {
        setError('')
        setPaymentDialog(0)
        let checks = 0
        const currentSubPlan = session.subscription.paymentProcessorData?.p
        const checkSubscription = () =>
            getSubscriptionRequest(session.auth_token)
                .request()
                .then((response: ISubscriptionResponse) => {
                    if (response.subscription.paymentProcessorData?.p === currentSubPlan) {
                        if (checks < 3) {
                            setCheckTimeout(setTimeout(checkSubscription, 2000) as any)
                            ++checks
                        } else {
                            setError(
                                fillLocalization(
                                    localization.subscription.upgradeProcessing,
                                    tierNumberToName(tier)
                                )
                            )
                            setCheckTimeout(setTimeout(checkSubscription, 2000) as any)
                        }
                    } else {
                        capture('subscription_change:succeeded', {
                            location: 'subscription',
                            newTier: tier,
                            giftKeyType: giftKeyResponse?.giftKeyType,
                        })
                        setSession({
                            ...session,
                            subscription: response.subscription,
                            priority: response.priority,
                            settings: {
                                ...session.settings,
                                unsubSurveyShown: false,
                            },
                        })
                        setPaymentDialog(3)
                    }
                })
                .catch((error) => {
                    logError(error)
                    setError(`${error}`)
                })
        getSubscriptionChangeRequest(session.auth_token, tierNumberToNameCaps(tier))
            .request()
            .then(() => {
                setTimeout(checkSubscription, 2000)
            })
            .catch((error) => {
                logError(error)
                setError(`${error}`)
            })
    }

    function openUnsubscribeLink() {
        // Click unsubscribe link
        capture('unsubscribe_link:clicked', {
            location: 'subscription',
        })
        const url = session.subscription?.paymentProcessorData?.c
        if (url) {
            window.open(url, '_blank')
        } else {
            toast(localization.subscription.unsubscribeLinkMissing, { type: 'error' })
        }
    }

    if (session.information.banStatus !== BanStatus.NOT_BANNED) {
        return (
            <SubscriptionDialog
                style={{
                    maxWidth: '500px',
                }}
            >
                <SubscribeHeader>{localization.ban.banned[0]}</SubscribeHeader>
                <div>
                    <a href="https://novelai.net/terms" target="_blank" rel="noreferrer">
                        {localization.ban.banned[1]}
                    </a>
                </div>
                <div>{localization.ban.banned[2]}</div>
                <div>{localization.ban.banned[3]}</div>
                <DontButton onClick={closeDialog}>{localization.cta.ok}</DontButton>
            </SubscriptionDialog>
        )
    }

    switch (paymentDialog) {
        case 1: {
            return (
                <SubscriptionDialog>
                    <SubscriptionPurchase onSuccess={onSuccess} />
                    {error ? <ErrorInfo>{error}</ErrorInfo> : null}
                </SubscriptionDialog>
            )
        }
        case 2: {
            return (
                <SubscriptionDialog>
                    <SubscribeHeader>
                        {props.actionBlocked
                            ? localization.subscription.needAsubscription
                            : localization.subscription.notSubscribed}
                    </SubscribeHeader>
                    <SubscribeText>{localization.subscription.upgradeSubscriptionQ}</SubscribeText>
                    <SubscribeButton
                        onClick={() => {
                            setPaymentDialog(1)
                            capture('subscription_tiers:clicked', {
                                location: 'subscription',
                            })
                        }}
                    >
                        {localization.subscription.takeMeThere}
                    </SubscribeButton>
                    <SubscribeButton
                        onClick={() => {
                            setPaymentDialog(4)
                            capture('gift_key:clicked', {
                                location: 'subscription',
                            })
                        }}
                    >
                        {localization.subscription.activateGiftKey}
                    </SubscribeButton>
                    <DontButton onClick={closeDialog}>{localization.subscription.noTakeMeBack}</DontButton>
                    {error ? <ErrorInfo>{error}</ErrorInfo> : null}
                </SubscriptionDialog>
            )
        }
        case 3: {
            return (
                <SubscriptionDialog>
                    <SubscribeHeader>
                        {fillLocalization(
                            localization.subscription.youAreSubscribedTo,
                            tierNumberToName(session.subscription.tier)
                        )}
                    </SubscribeHeader>
                    <SubscribeText>
                        <p>
                            {session.subscription.paymentProcessorData?.s === 'active' ||
                            session.subscription.paymentProcessorData?.s === 'high_risk'
                                ? splitFillLocalization(
                                      localization.subscription.yourSubscriptionRenews,
                                      dayjs
                                          .unix(session.subscription?.expiresAt ?? 0)
                                          .format('YYYY/MM/DD @ hh:mma')
                                  ).map((part, index) => {
                                      switch (index) {
                                          case 1: {
                                              return <strong key={index}>{part}</strong>
                                          }
                                          default: {
                                              return <span key={index}>{part}</span>
                                          }
                                      }
                                  })
                                : splitFillLocalization(
                                      localization.subscription.yourSubscriptionEnds,
                                      dayjs
                                          .unix(session.subscription?.expiresAt ?? 0)
                                          .format('YYYY/MM/DD @ hh:mma')
                                  ).map((part, index) => {
                                      switch (index) {
                                          case 1: {
                                              return <strong key={index}>{part}</strong>
                                          }
                                          default: {
                                              return <span key={index}>{part}</span>
                                          }
                                      }
                                  })}
                        </p>
                        {session.subscription.paymentProcessorData?.s === 'high_risk' ? (
                            <p>{localization.subscription.yourSubScriptionHighRisk}</p>
                        ) : null}
                    </SubscribeText>
                    {session.subscription.paymentProcessorData?.s === 'active' ||
                    session.subscription.paymentProcessorData?.s === 'high_risk' ? (
                        <>
                            <Modal
                                type={ModalType.Large}
                                onRequestClose={() => {
                                    setUnsubscribeModal(false)
                                }}
                                isOpen={unsubscribeModal}
                                style={{
                                    justifyContent: 'center',
                                }}
                            >
                                <UnsubscribeModalContainer>
                                    <CloseButton
                                        onClick={() => {
                                            setUnsubscribeModal(false)
                                        }}
                                    >
                                        <div />
                                    </CloseButton>
                                    <Survey
                                        onSurveyClose={(failed) => {
                                            openUnsubscribeLink()
                                            setUnsubscribeModal(false)
                                            if (!failed) {
                                                setSurveyDone(true)
                                            }
                                        }}
                                    />
                                </UnsubscribeModalContainer>
                            </Modal>
                            <ChangeSubscriptionButton
                                onClick={() => {
                                    if (getUserSetting(session.settings, 'unsubSurveyShown')) {
                                        openUnsubscribeLink()
                                    } else {
                                        setUnsubscribeModal(true)
                                    }
                                }}
                            >
                                {localization.subscription.unsubscribe}
                            </ChangeSubscriptionButton>
                            {session.subscription.tier !== 3 ? (
                                <ChangeSubscriptionButton
                                    onClick={() => {
                                        setPaymentDialog(5)
                                        capture('change_subscription:clicked', {
                                            location: 'subscription',
                                        })
                                    }}
                                >
                                    {localization.subscription.upgradeSubscription}
                                </ChangeSubscriptionButton>
                            ) : (
                                <></>
                            )}
                            {session.subscription.paymentProcessorData?.u ? (
                                <ChangeSubscriptionLink
                                    href={session.subscription.paymentProcessorData?.u}
                                    target="_blank"
                                    onClick={() => {
                                        capture('update_payment_link:clicked', {
                                            location: 'subscription',
                                        })
                                    }}
                                >
                                    {localization.subscription.updatePaymentMethod}
                                </ChangeSubscriptionLink>
                            ) : null}

                            <ChangeSubscriptionButton
                                onClick={() => {
                                    setPaymentDialog(4)
                                    capture('gift_key:clicked', {
                                        location: 'subscription',
                                    })
                                }}
                            >
                                {localization.subscription.activateGiftKey}
                            </ChangeSubscriptionButton>
                            <DontButton onClick={closeDialog}>OK</DontButton>
                        </>
                    ) : (
                        <>
                            <ChangeSubscriptionButton
                                onClick={() => {
                                    setPaymentDialog(7)
                                    capture('replace_subscription:clicked', {
                                        location: 'subscription',
                                    })
                                }}
                            >
                                {localization.subscription.replaceSubscription}
                            </ChangeSubscriptionButton>
                            <ChangeSubscriptionButton
                                onClick={() => {
                                    setPaymentDialog(4)
                                    capture('gift_key:clicked', {
                                        location: 'subscription',
                                    })
                                }}
                            >
                                {localization.subscription.activateGiftKey}
                            </ChangeSubscriptionButton>
                            <DontButton onClick={closeDialog}>OK</DontButton>
                        </>
                    )}
                    {error ? <ErrorInfo>{error}</ErrorInfo> : null}
                </SubscriptionDialog>
            )
        }
        case 4: {
            return (
                <SubscriptionDialog>
                    {!showGiftKeyResult ? (
                        <>
                            <SubscribeHeader>{localization.subscription.activateGiftKey}</SubscribeHeader>
                            <SubscribeGiftKeyInput
                                placeholder="Key"
                                value={giftKey}
                                onChange={(e) => setGiftKey(e.target.value)}
                            />
                            <SubscribeButton
                                onClick={() => {
                                    onGiftKey()
                                    capture('gift_key:submitted', {
                                        location: 'subscription',
                                    })
                                }}
                            >
                                {localization.subscription.activate}
                            </SubscribeButton>
                            {error ? <ErrorInfo>{error}</ErrorInfo> : null}
                        </>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <UpdateNotifierBackground />
                                <BigIcon />
                                <SubscribeHeader>{localization.subscription.giftKeySuccess}</SubscribeHeader>
                                <SubscribeText>
                                    <PurchasedTitle>
                                        {giftKeyResponse?.giftKeyType === GiftKeyType.Subscription && (
                                            <>
                                                {fillLocalization(
                                                    localization.subscription.giftKeySuccessSubscription,
                                                    tierNumberToName(giftKeyResponse?.tier ?? 0)
                                                )}
                                            </>
                                        )}
                                        {(giftKeyResponse?.giftKeyType === GiftKeyType.Steps ||
                                            (giftKeyResponse?.giftKeyType === GiftKeyType.SubAndSteps &&
                                                redeemedStepsOnly)) && (
                                            <>
                                                {fillLocalization(
                                                    localization.subscription.giftKeySuccessAnlas,
                                                    giftKeyResponse?.steps ?? 'unknown number of'
                                                )}
                                            </>
                                        )}
                                        {giftKeyResponse?.giftKeyType === GiftKeyType.SubAndSteps &&
                                            !redeemedStepsOnly && (
                                                <>
                                                    {fillLocalization(
                                                        localization.subscription
                                                            .giftKeySuccessSubscriptionAndAnlas,
                                                        tierNumberToName(giftKeyResponse?.tier ?? 0),
                                                        giftKeyResponse?.steps ?? 'unknown number of'
                                                    )}
                                                </>
                                            )}
                                    </PurchasedTitle>
                                </SubscribeText>
                                <DontButton onClick={closeDialog} style={{ width: '100%' }}>
                                    {localization.cta.ok}
                                </DontButton>
                            </div>
                        </>
                    )}
                </SubscriptionDialog>
            )
        }
        case 5: {
            return (
                <SubscriptionDialog>
                    <SubscribeHeader>{localization.subscription.upgradeSubscription}</SubscribeHeader>
                    <SubscriptionAboveInfo>
                        {localization.subscription.upgradeSubscriptionInfo}
                        {PurchasesDisabled && (
                            <WarningText
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                    textAlign: 'left',
                                    margin: 10,
                                }}
                            >
                                {localization.subscription.checkoutDisabled}
                                <br />
                                {localization.subscription.subLongerActivate}
                            </WarningText>
                        )}
                    </SubscriptionAboveInfo>
                    <SubscribeContainer>
                        <SubscriptionTierList>
                            <SubscriptionTier
                                color={theme.colors.textHeadingsOptions[0]}
                                onClick={() => {
                                    setChangeTier(1)
                                    setPaymentDialog(6)
                                }}
                                disabled={PurchasesDisabled || session.subscription.tier >= 1}
                            >
                                <TabletTierContent />
                            </SubscriptionTier>
                            <SubscriptionTier
                                color={theme.colors.textHeadingsOptions[1]}
                                onClick={() => {
                                    setChangeTier(2)
                                    setPaymentDialog(6)
                                }}
                                disabled={PurchasesDisabled || session.subscription.tier >= 2}
                            >
                                <ScrollTierContent />
                            </SubscriptionTier>
                            <SubscriptionTier
                                color={theme.colors.textHeadingsOptions[3]}
                                onClick={() => {
                                    setChangeTier(3)
                                    setPaymentDialog(6)
                                }}
                                disabled={PurchasesDisabled || session.subscription.tier >= 3}
                            >
                                <OpusTierContent />
                            </SubscriptionTier>
                        </SubscriptionTierList>
                    </SubscribeContainer>
                    <TierFinePrint>{localization.subscription.tierCards.finePrintImage}</TierFinePrint>

                    {error ? <ErrorInfo>{error}</ErrorInfo> : null}
                </SubscriptionDialog>
            )
        }
        case 6: {
            return (
                <SubscriptionDialog>
                    <SubscribeHeader>{'Upgrade Subscription Tier'}</SubscribeHeader>
                    <SubscribeText>
                        {fillLocalization(
                            localization.subscription.upgradeSubscriptionConfirm,
                            tierNumberToName(session.subscription.tier),
                            tierNumberToName(changeTier)
                        )}
                        <br />
                        <br />
                        {splitFillLocalization(
                            localization.subscription.upgradeSubscriptionCharge,
                            calculateUpgradePrice(session.subscription, changeTier)
                        ).map((text, index) => {
                            switch (index) {
                                case 1: {
                                    return <strong key={index}>{text}</strong>
                                }
                                default: {
                                    return <span key={index}>{text}</span>
                                }
                            }
                        })}
                    </SubscribeText>
                    <SubscribeButton
                        onClick={() => {
                            onSubscriptionChange(changeTier)
                        }}
                    >
                        {localization.subscription.upgradeConfirm}
                    </SubscribeButton>
                    <DontButton onClick={() => setPaymentDialog(5)}>
                        {localization.subscription.noTakeMeBack}
                    </DontButton>
                    {error ? <ErrorInfo>{error}</ErrorInfo> : null}
                </SubscriptionDialog>
            )
        }
        case 7: {
            return (
                <SubscriptionDialog>
                    <SubscriptionPurchase
                        onSuccess={onSuccess}
                        explanation={localization.subscription.replaceSubscriptionInfo}
                    />
                    {error ? <ErrorInfo>{error}</ErrorInfo> : null}
                </SubscriptionDialog>
            )
        }
        case 8: {
            // Gift key confirmation dialog
            return (
                <SubscriptionDialog>
                    <SubscribeHeader>{localization.subscription.giftKeyConfirmation}</SubscribeHeader>
                    <SubscribeText>
                        {giftKeyResponse?.giftKeyType === GiftKeyType.Subscription && (
                            <>
                                {splitFillLocalization(
                                    localization.subscription.giftKeySubscriptionAlreadySubbedConfirm[0],
                                    tierNumberToName(giftKeyResponse?.tier ?? 0),
                                    giftKeyResponse.duration
                                        ? giftKeyResponse.duration / 86400 + ' days'
                                        : 'unknown duration'
                                ).map((text, index) => {
                                    switch (index) {
                                        case 1: {
                                            return <strong key={index}>{text}</strong>
                                        }
                                        default: {
                                            return <span key={index}>{text}</span>
                                        }
                                    }
                                })}
                                <br />
                                {localization.subscription.giftKeySubscriptionAlreadySubbedConfirm[1]}
                            </>
                        )}
                        {giftKeyResponse?.giftKeyType === GiftKeyType.SubAndSteps && (
                            <>
                                {splitFillLocalization(
                                    localization.subscription
                                        .giftKeySubcriptionAlreadySubbedAndAnlasConfirm[0],
                                    tierNumberToName(giftKeyResponse?.tier ?? 0),
                                    giftKeyResponse.duration
                                        ? giftKeyResponse.duration / 86400 + ' days'
                                        : 'unknown duration',
                                    giftKeyResponse.steps ?? 'unknown number of'
                                ).map((text, index) => {
                                    switch (index) {
                                        case 1: {
                                            return <strong key={index}>{text}</strong>
                                        }
                                        default: {
                                            return <span key={index}>{text}</span>
                                        }
                                    }
                                })}
                                <br />
                                {session.subscription.paymentProcessorData?.t ===
                                PaymentProcessorType.PADDLE ? (
                                    <>
                                        {
                                            localization.subscription
                                                .giftKeySubcriptionAlreadySubbedAndAnlasConfirm[1]
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            localization.subscription
                                                .giftKeySubcriptionAlreadySubbedAndAnlasConfirm[2]
                                        }
                                    </>
                                )}
                            </>
                        )}
                    </SubscribeText>
                    {giftKeyResponse?.giftKeyType === GiftKeyType.SubAndSteps && (
                        <SubscribeButton
                            onClick={() => {
                                onGiftKey(true)
                                capture('gift_key_redeem_just_anlas:submitted', {
                                    location: 'subscription',
                                })
                            }}
                        >
                            {localization.subscription.redeemJustAnlas}
                        </SubscribeButton>
                    )}
                    {session.subscription.paymentProcessorData?.t !== PaymentProcessorType.PADDLE && (
                        <SubscribeButton
                            onClick={() => {
                                onGiftKey(false, true)
                                capture('gift_key_replace:submitted', {
                                    location: 'subscription',
                                })
                            }}
                        >
                            {localization.subscription.replaceSubscription}
                        </SubscribeButton>
                    )}
                    <DontButton onClick={() => setPaymentDialog(4)}>
                        {localization.subscription.noTakeMeBack}
                    </DontButton>
                    {error ? <ErrorInfo>{error}</ErrorInfo> : null}
                </SubscriptionDialog>
            )
        }
        default: {
            return (
                <SubscriptionDialog>
                    {error ? (
                        <>
                            <LoadingSpinner visible={true} />
                            <ErrorInfo>{error}</ErrorInfo>
                        </>
                    ) : (
                        <LoadingSpinner visible={true} />
                    )}
                </SubscriptionDialog>
            )
        }
    }
}

function tierToPrice(tier: number): number {
    return tier === 1 ? 10 : tier === 2 ? 15 : tier === 3 ? 25 : 0
}

function calculateUpgradePrice(subscription: UserSubscription, newTier: number): string {
    if (!subscription.paymentProcessorData?.n) {
        return 'NaN'
    }
    const newPrice = tierToPrice(newTier)
    const oldPrice = tierToPrice(subscription.tier)

    const remainingDays = Math.round(
        Math.abs((Date.now() / 1000 - subscription.paymentProcessorData.n) / 86400)
    )

    const unusedCredit = oldPrice * (remainingDays / 30)
    const remainingNew = newPrice * (remainingDays / 30)
    const calcPrice = -(unusedCredit - remainingNew)

    return calcPrice.toFixed(2)
}

export function TierGrid(props: {
    style?: CSSProperties
    children?: JSX.Element | JSX.Element[]
}): JSX.Element {
    const localization = useLocalization()
    const { width } = useWindowSize()
    const [collapsed, setCollapsed] = useState(false)
    useEffect(() => {
        if (width) {
            if (width > 1000 && collapsed) setCollapsed(false)
            else if (width <= 1000 && !collapsed) setCollapsed(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '2.3fr 1fr 1fr 1fr 1fr',
                alignItems: 'stretch',
            }}
        >
            <TierGridBox
                tier={0}
                borderBottom
                alignLeft
                style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <PenTipIcon
                    style={{
                        width: 50,
                        height: 50,
                        margin: '0 16px',
                    }}
                />
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: 7,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <SubIcon icon={Paper.src} color={'#D9FFE1'} />
                        <TierName color={'#D9FFE1'}>{localization.tier.paper}</TierName>
                    </div>
                    <TierPriceNew>
                        <span>{localization.subscription.tierCards.paperPrice}</span>
                        <span></span>
                    </TierPriceNew>
                </div>
            </TierGridBox>

            <TierGridBox tier={2} borderBottom borderRight>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: 7,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <SubIcon icon={Tablet.src} color={Dark.colors.textHeadings} />
                        <TierName color={Dark.colors.textHeadings}>{localization.tier.tablet}</TierName>
                    </div>
                    <TierPriceNew>
                        <span>$10</span>
                        <span>{localization.subscription.tierCards.perMonth}</span>
                    </TierPriceNew>
                </div>
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: 7,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <SubIcon icon={Scroll.src} color={Dark.colors.textHeadingsOptions[1]} />
                        <TierName color={Dark.colors.textHeadingsOptions[1]}>
                            {localization.tier.scroll}
                        </TierName>
                    </div>
                    <TierPriceNew>
                        <span>$15</span>
                        <span>{localization.subscription.tierCards.perMonth}</span>
                    </TierPriceNew>
                    <TierValueNew color={Dark.colors.textHeadingsOptions[1]}>
                        <div>{localization.subscription.tierCards.bestValue}</div>
                    </TierValueNew>
                </div>
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: 7,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <SubIcon icon={Opus.src} color={Dark.colors.textHeadingsOptions[3]} />
                        <TierName color={Dark.colors.textHeadingsOptions[3]}>
                            {localization.tier.opus}
                        </TierName>
                    </div>
                    <TierPriceNew>
                        <span>$25</span>
                        <span>{localization.subscription.tierCards.perMonth}</span>
                    </TierPriceNew>
                    <TierValueNew color={Dark.colors.textHeadingsOptions[3]}>
                        <div>{localization.subscription.tierCards.forTheEnthusiast}</div>
                    </TierValueNew>
                </div>
            </TierGridBox>

            <TierGridBand>{localization.subscription.tierCards.imageGen}</TierGridBand>
            <TierGridBox tier={0} borderBottom alignLeft>
                <TierBenefitNameNew>{localization.subscription.tierCards.imageGen}</TierBenefitNameNew>
                <TierBenefitDescNew>{localization.subscription.tierCards.imageGenSub}</TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={2} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <TierBenefitNameNew>{localization.subscription.tierCards.unlimited}**</TierBenefitNameNew>
            </TierGridBox>

            <TierGridBox tier={0} borderBottom alignLeft>
                <TierBenefitNameNew>NovelAI Diffusion Anime V3</TierBenefitNameNew>
                <TierBenefitDescNew>
                    {localization.subscription.tierCards.animeDiffusionSub}
                </TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={2} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <TierBenefitNameNew>{localization.subscription.tierCards.unlimited}**</TierBenefitNameNew>
            </TierGridBox>

            <TierGridBox tier={0} borderBottom alignLeft>
                <TierBenefitNameNew>NovelAI Diffusion Furry V3</TierBenefitNameNew>
                <TierBenefitDescNew>
                    {localization.subscription.tierCards.furryDiffusionSub}
                </TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={2} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <TierBenefitNameNew>{localization.subscription.tierCards.unlimited}**</TierBenefitNameNew>
            </TierGridBox>

            <TierGridBand>{localization.subscription.tierCards.textGeneration}</TierGridBand>

            <TierGridBox tier={0} borderBottom alignLeft>
                <TierBenefitNameNew>{localization.subscription.tierCards.freeGenerations}</TierBenefitNameNew>
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <TierBenefitNameNew>100*</TierBenefitNameNew>
            </TierGridBox>
            <TierGridBox tier={2} borderBottom borderRight>
                <TierBenefitNameNew>{localization.subscription.tierCards.unlimited}</TierBenefitNameNew>
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <TierBenefitNameNew>{localization.subscription.tierCards.unlimited}</TierBenefitNameNew>
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <TierBenefitNameNew>{localization.subscription.tierCards.unlimited}</TierBenefitNameNew>
            </TierGridBox>

            <TierGridBox tier={0} borderBottom alignLeft>
                <TierBenefitNameNew>{localization.subscription.tierCards.tokensOfMemory}</TierBenefitNameNew>
                <TierBenefitDescNew>
                    {localization.subscription.tierCards.tokensOfMemorySub}
                </TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <TierBenefitNameNew>~{(1024 * 8 * 4).toLocaleString()}</TierBenefitNameNew>
                <TierBenefitDescNew>
                    {fillLocalization(
                        localization.subscription.tierCards.nTokens,
                        (1024 * 8).toLocaleString()
                    )}
                </TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={2} borderBottom borderRight>
                <TierBenefitNameNew>~{(1024 * 4 * 4).toLocaleString()}</TierBenefitNameNew>
                <TierBenefitDescNew>
                    {fillLocalization(
                        localization.subscription.tierCards.nTokens,
                        (1024 * 4).toLocaleString()
                    )}
                </TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <TierBenefitNameNew>~{(1024 * 8 * 4).toLocaleString()}</TierBenefitNameNew>
                <TierBenefitDescNew>
                    {fillLocalization(
                        localization.subscription.tierCards.nTokens,
                        (1024 * 8).toLocaleString()
                    )}
                </TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <TierBenefitNameNew>~{(1024 * 8 * 4).toLocaleString()}</TierBenefitNameNew>
                <TierBenefitDescNew>
                    {fillLocalization(
                        localization.subscription.tierCards.nTokens,
                        (1024 * 8).toLocaleString()
                    )}
                </TierBenefitDescNew>
            </TierGridBox>

            <TierGridBox tier={0} borderBottom alignLeft>
                <TierBenefitNameNew>Kayra 13b</TierBenefitNameNew>
                <TierBenefitDescNew>{localization.models.kayra.description}</TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={2} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <TierCheckIcon />
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <TierCheckIcon />
            </TierGridBox>

            <TierGridBox tier={0} borderBottom alignLeft>
                <TierBenefitNameNew>Llama 3 Erato 70b</TierBenefitNameNew>
                <TierBenefitDescNew>{localization.models.erato.description}</TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <TierDashIcon />
            </TierGridBox>
            <TierGridBox tier={2} borderBottom borderRight>
                <TierDashIcon />
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <TierDashIcon />
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <TierCheckIcon />
            </TierGridBox>

            <TierGridBand>{localization.subscription.tierCards.extraFeatures}</TierGridBand>

            <TierGridBox tier={0} borderBottom alignLeft>
                <TierBenefitNameNew>{localization.general.anlas}</TierBenefitNameNew>
                <TierBenefitDescNew>{localization.subscription.tierCards.anlasSub}</TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <TierDashIcon />
            </TierGridBox>
            <TierGridBox tier={2} borderBottom borderRight>
                <TierBenefitNameNew>1,000</TierBenefitNameNew>
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <TierBenefitNameNew>1,000</TierBenefitNameNew>
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <TierBenefitNameNew>10,000</TierBenefitNameNew>
            </TierGridBox>

            <TierGridBox tier={0} borderBottom alignLeft>
                <TierBenefitNameNew>{localization.subscription.tierCards.TTS}</TierBenefitNameNew>
                <TierBenefitDescNew>{localization.subscription.tierCards.TTSSub}</TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <TierBenefitNameNew>100</TierBenefitNameNew>
            </TierGridBox>
            <TierGridBox tier={2} borderBottom borderRight>
                <TierBenefitNameNew>{localization.subscription.tierCards.advancedAI}</TierBenefitNameNew>
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <TierBenefitNameNew>{localization.subscription.tierCards.advancedAI}</TierBenefitNameNew>
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <TierBenefitNameNew>{localization.subscription.tierCards.advancedAI}</TierBenefitNameNew>
            </TierGridBox>

            <TierGridBox tier={0} borderBottom alignLeft>
                <TierBenefitNameNew>
                    {localization.subscription.tierCards.experimentalFeatures}
                </TierBenefitNameNew>
                <TierBenefitDescNew>
                    {localization.subscription.tierCards.experimentalFeaturesSub}
                </TierBenefitDescNew>
            </TierGridBox>
            <TierGridBox tier={1} borderBottom borderRight>
                <TierDashIcon />
            </TierGridBox>
            <TierGridBox tier={2} borderBottom borderRight>
                <TierDashIcon />
            </TierGridBox>
            <TierGridBox tier={3} borderBottom borderRight>
                <TierDashIcon />
            </TierGridBox>
            <TierGridBox tier={4} borderBottom>
                <TierCheckIcon />
            </TierGridBox>
            {props.children}
        </div>
    )
}

export const TierGridBox = styled.div<{
    tier: number
    borderBottom?: boolean
    borderRight?: boolean
    alignLeft?: boolean
}>`
    position: relative;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: ${(props) => (props.alignLeft ? 'flex-start' : 'center')};
    border-bottom: ${(props) => (props.borderBottom ? '1px solid #ffffff1a' : 'none')};
    border-right: ${(props) => (props.borderRight ? '1px solid #ffffff1a' : 'none')};
    background-color: ${(props) =>
        props.tier === 1
            ? transparentize(0.88, '#D9FFE1')
            : props.tier === 2
            ? transparentize(0.88, Dark.colors.textHeadings)
            : props.tier === 3
            ? transparentize(0.88, Dark.colors.textHeadingsOptions[1])
            : props.tier === 4
            ? transparentize(0.88, Dark.colors.textHeadingsOptions[3])
            : 'transparent'};
`

export const TierText = styled.div`
    display: flex;
    flex-direction: column;

    font-weight: 700;
    font-size: 2.625rem;
    font-family: ${(props) => props.theme.fonts.headings};
    max-width: 353px;

    line-height: 50px;

    @media (max-width: ${mobileSize}) {
        align-items: center;
        position: unset;
        > span:last-child {
            max-width: unset;
        }
        margin-bottom: 20px;
    }

    @media (max-width: 900px) {
        align-items: flex-start;
        width: 305px;
        > span:first-child {
            font-size: 1;
        }

        > span:last-child {
            font-size: 1.5rem;
            max-width: 200px;
            line-height: 36px;
        }
    }
`

export const TierPriceNew = styled.div`
    line-height: 3.25rem;
    font-family: ${(props) => props.theme.fonts.headings};
    > span:first-child {
        font-size: 2.75rem;
        font-weight: 700;
    }

    > span:last-child {
        font-size: 1.375rem;
        font-weight: 400;
    }

    @media (max-width: 1200px) {
        > span:first-child {
            font-size: 2rem;
        }

        > span:last-child {
            font-size: 1rem;
        }
    }
`

export const TierValueNew = styled.div<{ color: string }>`
    position: absolute;
    width: 100%;
    left: 0;
    top: -15px;
    display: flex;
    justify-content: center;
    height: fit-content;
    > div {
        width: fit-content;
        height: fit-content;
        background-color: ${(props) => props.color};
        padding: 4px 16px;
        line-height: 20px;
        font-weight: 700;
        color: ${(props) => props.theme.colors.textMain};
        outline: 1px solid ${(props) => props.color};
    }

    @media (max-width: 1200px) {
        > div {
            padding: 2px 4px;
            font-size: 0.875rem;
        }
    }
`

export const TierBenefitDescNew = styled.div<{ color?: string }>`
    position: relative;
    color: ${(props) => props.color ?? props.theme.colors.textMain};
    opacity: 0.64;
    font-size: 0.875rem;

    margin-top: 4px;
`
export const TierBenefitNameNew = styled.div<{ color?: string }>`
    color: ${(props) => props.color ?? props.theme.colors.textMain};
    font-weight: 600;
`

export const TierGridBand = styled.div`
    background-color: ${(props) => props.theme.colors.bg1};
    padding: 20px 24px;
    border-bottom: 1px solid #ffffff1a;
    grid-column: span 5;
    font-family: ${(props) => props.theme.fonts.headings};
    font-size: 1.25rem;
    font-weight: 700;
`

export function TierButton(props: { tier: 1 | 2 | 3 | 4; onClick: () => void }): JSX.Element {
    const localization = useLocalization()
    switch (props.tier) {
        case 1: {
            return (
                <TierButtonSkeleton
                    color={'#D9FFE1'}
                    onClick={props.onClick}
                    name={localization.tier.paper}
                    price={localization.subscription.tierCards.paperPrice}
                    pricePeriod={''}
                    backgroundTransparency={0.88}
                    icon={Paper.src}
                />
            )
        }
        case 2: {
            return (
                <TierButtonSkeleton
                    color={Dark.colors.textHeadings}
                    onClick={props.onClick}
                    name={localization.tier.tablet}
                    price={'$10'}
                    pricePeriod={localization.subscription.tierCards.perMonth}
                    backgroundTransparency={0.88}
                    icon={Tablet.src}
                />
            )
        }
        case 3: {
            return (
                <TierButtonSkeleton
                    color={Dark.colors.textHeadingsOptions[1]}
                    onClick={props.onClick}
                    name={localization.tier.scroll}
                    price={'$15'}
                    pricePeriod={localization.subscription.tierCards.perMonth}
                    backgroundTransparency={0.88}
                    icon={Scroll.src}
                    value={localization.subscription.tierCards.bestValue}
                />
            )
        }
        case 4: {
            return (
                <TierButtonSkeleton
                    color={Dark.colors.textHeadingsOptions[3]}
                    onClick={props.onClick}
                    name={localization.tier.opus}
                    price={'$25'}
                    pricePeriod={localization.subscription.tierCards.perMonth}
                    backgroundTransparency={0.88}
                    icon={Opus.src}
                    value={localization.subscription.tierCards.forTheEnthusiast}
                />
            )
        }
    }
    return <></>
}

function TierButtonSkeleton(props: {
    color: string
    onClick: () => void
    name: string
    price: string
    pricePeriod: string
    backgroundTransparency: number
    icon: string
    value?: string
}) {
    return (
        <TierButtonElement
            onClick={() => {
                props.onClick()
            }}
            style={{
                backgroundColor: transparentize(props.backgroundTransparency, props.color),
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 8,
                }}
            >
                <SubIcon
                    icon={props.icon}
                    color={props.color}
                    style={{
                        width: 30,
                        height: 30,
                        margin: 0,
                    }}
                />
                <span
                    style={{
                        color: props.color,
                        fontWeight: 700,
                    }}
                >
                    {props.name}
                </span>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 8,
                }}
            >
                <span>
                    <PriceText>{props.price}</PriceText>
                    <PriceText
                        style={{
                            fontSize: '0.875rem',
                        }}
                    >
                        {props.pricePeriod}
                    </PriceText>
                </span>
                <RoundedPlusIcon />
            </div>
            {props.value ? (
                <TierValueNew
                    color={props.color}
                    style={{ top: -4, justifyContent: 'flex-end', right: 24, left: 'unset' }}
                >
                    <div
                        style={{
                            fontSize: '0.625rem',
                            padding: '2px 8px',
                            lineHeight: '15px',
                        }}
                    >
                        {props.value}
                    </div>
                </TierValueNew>
            ) : null}
        </TierButtonElement>
    )
}

const PriceText = styled.span`
    font-size: 1.125rem;
    font-weight: 700;
    font-family: ${(props) => props.theme.fonts.headings};
`

const TierButtonElement = styled(SubtleButton)`
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 400px;
`

export function TierModal(props: {
    page: number
    setPage: (page: number) => void
    onButtonClick: (tier: number) => void
}): JSX.Element {
    const localization = useLocalization()

    // Page 0 is closed
    if (props.page === 0) return <></>
    return (
        <Modal
            type={ModalType.Large}
            isOpen={props.page !== 0}
            onRequestClose={() => props.setPage(0)}
            shouldCloseOnOverlayClick={true}
            style={{
                maxHeight: '100%',
                maxWidth: '100%',
                height: '100%',
                width: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 32,
                    height: '100%',
                    width: '100%',
                    maxHeight: '100%',
                    overflowY: 'hidden',
                }}
            >
                <CloseButton onClick={() => props.setPage(0)}>
                    <div />
                </CloseButton>
                <div
                    style={{
                        height: 122,
                        flex: '0 0 auto',
                    }}
                ></div>
                {props.page === 1 && (
                    <TierModalBox
                        name={localization.tier.paper}
                        color={'#D9FFE1'}
                        price={localization.subscription.tierCards.paperPrice}
                        pricePeriod={''}
                        icon={Paper.src}
                        features={
                            <TierModalColumn>
                                <TierModalRow>
                                    <TierBenefitNameNew>
                                        {localization.subscription.tierCards.freeGenerations}
                                    </TierBenefitNameNew>
                                    <TierBenefitNameNew>100*</TierBenefitNameNew>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.tokensOfMemory}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.tokensOfMemorySub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>
                                            ~{(1024 * 8 * 4).toLocaleString()}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {fillLocalization(
                                                localization.subscription.tierCards.nTokens,
                                                (1024 * 8).toLocaleString()
                                            )}
                                        </TierBenefitDescNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>Kayra 13b</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.models.kayra.description}
                                        </TierBenefitDescNew>
                                    </div>
                                    <TierCheckIcon />
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>Llama 3 Erato 70b</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.models.erato.description}
                                        </TierBenefitDescNew>
                                    </div>
                                    <TierDashIcon />
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        imageFeatures={
                            <TierModalColumn>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.imageGen}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.imageGenSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>NovelAI Diffusion Anime V3</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.animeDiffusionSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>NovelAI Diffusion Furry V3</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.furryDiffusionSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        extraFeatures={
                            <TierModalColumn>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>{localization.general.anlas}</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.anlasSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierDashIcon />
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.TTS}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.TTSSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>100</TierBenefitNameNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.experimentalFeatures}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.experimentalFeaturesSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierDashIcon />
                                    </div>
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        onButtonClick={() => props.onButtonClick(1)}
                        buttonText={localization.landing.getStarted}
                    />
                )}
                {props.page === 2 && (
                    <TierModalBox
                        name={localization.tier.tablet}
                        color={Dark.colors.textHeadings}
                        price={'$10'}
                        pricePeriod={localization.subscription.tierCards.perMonth}
                        icon={Tablet.src}
                        features={
                            <TierModalColumn>
                                <TierModalRow>
                                    <TierBenefitNameNew>
                                        {localization.subscription.tierCards.freeGenerations}
                                    </TierBenefitNameNew>
                                    <TierBenefitNameNew>
                                        {localization.subscription.tierCards.unlimited}
                                    </TierBenefitNameNew>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.tokensOfMemory}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.tokensOfMemorySub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>
                                            ~{(1024 * 4 * 4).toLocaleString()}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {fillLocalization(
                                                localization.subscription.tierCards.nTokens,
                                                (1024 * 4).toLocaleString()
                                            )}
                                        </TierBenefitDescNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>Kayra 13b</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.models.kayra.description}
                                        </TierBenefitDescNew>
                                    </div>
                                    <TierCheckIcon />
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>Llama 3 Erato 70b</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.models.erato.description}
                                        </TierBenefitDescNew>
                                    </div>
                                    <TierDashIcon />
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        imageFeatures={
                            <TierModalColumn>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.imageGen}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.imageGenSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>NovelAI Diffusion Anime V3</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.animeDiffusionSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>NovelAI Diffusion Furry V3</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.furryDiffusionSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        extraFeatures={
                            <TierModalColumn>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>{localization.general.anlas}</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.anlasSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>1,000</TierBenefitNameNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.TTS}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.TTSSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.advancedAI}
                                        </TierBenefitNameNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.experimentalFeatures}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.experimentalFeaturesSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierDashIcon
                                            style={{
                                                width: 24,
                                                height: 24,
                                            }}
                                        />
                                    </div>
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        onButtonClick={() => props.onButtonClick(2)}
                        buttonText={localization.landing.getStarted}
                    />
                )}

                {props.page === 3 && (
                    <TierModalBox
                        name={localization.tier.scroll}
                        color={Dark.colors.textHeadingsOptions[1]}
                        price={'$15'}
                        pricePeriod={localization.subscription.tierCards.perMonth}
                        icon={Scroll.src}
                        value={localization.subscription.tierCards.bestValue}
                        features={
                            <TierModalColumn>
                                <TierModalRow>
                                    <TierBenefitNameNew>
                                        {localization.subscription.tierCards.freeGenerations}
                                    </TierBenefitNameNew>
                                    <TierBenefitNameNew>
                                        {localization.subscription.tierCards.unlimited}
                                    </TierBenefitNameNew>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.tokensOfMemory}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.tokensOfMemorySub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>
                                            ~{(1024 * 8 * 4).toLocaleString()}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {fillLocalization(
                                                localization.subscription.tierCards.nTokens,
                                                (1024 * 8).toLocaleString()
                                            )}
                                        </TierBenefitDescNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>Kayra 13b</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.models.kayra.description}
                                        </TierBenefitDescNew>
                                    </div>
                                    <TierCheckIcon />
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>Llama 3 Erato 70b</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.models.erato.description}
                                        </TierBenefitDescNew>
                                    </div>
                                    <TierDashIcon />
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        imageFeatures={
                            <TierModalColumn>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.imageGen}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.imageGenSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>NovelAI Diffusion Anime V3</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.animeDiffusionSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>NovelAI Diffusion Furry V3</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.furryDiffusionSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        extraFeatures={
                            <TierModalColumn>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>{localization.general.anlas}</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.anlasSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>1,000</TierBenefitNameNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.TTS}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.TTSSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.advancedAI}
                                        </TierBenefitNameNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.experimentalFeatures}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.experimentalFeaturesSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierDashIcon
                                            style={{
                                                width: 24,
                                                height: 24,
                                            }}
                                        />
                                    </div>
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        onButtonClick={() => props.onButtonClick(3)}
                        buttonText={localization.landing.getStarted}
                    />
                )}

                {props.page === 4 && (
                    <TierModalBox
                        name={localization.tier.opus}
                        color={Dark.colors.textHeadingsOptions[3]}
                        price={'$25'}
                        pricePeriod={localization.subscription.tierCards.perMonth}
                        icon={Opus.src}
                        value={localization.subscription.tierCards.forTheEnthusiast}
                        features={
                            <TierModalColumn>
                                <TierModalRow>
                                    <TierBenefitNameNew>
                                        {localization.subscription.tierCards.freeGenerations}
                                    </TierBenefitNameNew>
                                    <TierBenefitNameNew>
                                        {localization.subscription.tierCards.unlimited}
                                    </TierBenefitNameNew>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.tokensOfMemory}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.tokensOfMemorySub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>
                                            ~{(1024 * 8 * 4).toLocaleString()}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {fillLocalization(
                                                localization.subscription.tierCards.nTokens,
                                                (1024 * 8).toLocaleString()
                                            )}
                                        </TierBenefitDescNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>Kayra 13b</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.models.kayra.description}
                                        </TierBenefitDescNew>
                                    </div>
                                    <TierCheckIcon />
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>Llama 3 Erato 70b</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.models.erato.description}
                                        </TierBenefitDescNew>
                                    </div>
                                    <TierCheckIcon />
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        imageFeatures={
                            <TierModalColumn>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.imageGen}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.imageGenSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.unlimited}**
                                        </TierBenefitNameNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>NovelAI Diffusion Anime V3</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.animeDiffusionSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>NovelAI Diffusion Furry V3</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.furryDiffusionSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        extraFeatures={
                            <TierModalColumn>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>{localization.general.anlas}</TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.anlasSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>10,000</TierBenefitNameNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.TTS}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.TTSSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.advancedAI}
                                        </TierBenefitNameNew>
                                    </div>
                                </TierModalRow>
                                <TierModalRow>
                                    <div>
                                        <TierBenefitNameNew>
                                            {localization.subscription.tierCards.experimentalFeatures}
                                        </TierBenefitNameNew>
                                        <TierBenefitDescNew>
                                            {localization.subscription.tierCards.experimentalFeaturesSub}
                                        </TierBenefitDescNew>
                                    </div>
                                    <div>
                                        <TierCheckIcon />
                                    </div>
                                </TierModalRow>
                            </TierModalColumn>
                        }
                        onButtonClick={() => props.onButtonClick(4)}
                        buttonText={localization.landing.getStarted}
                    />
                )}

                <div
                    style={{
                        height: 92,
                        flex: '0 0 auto',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 24,
                            justifyContent: 'center',
                        }}
                    >
                        <InvertedButton
                            style={{
                                height: 40,
                                width: 40,
                            }}
                            disabled={props.page === 1}
                            onClick={() => {
                                const newPage = props.page - 1 < 1 ? 4 : props.page - 1
                                props.setPage(newPage)
                            }}
                        >
                            <RoundedChevronLeftIcon />
                        </InvertedButton>
                        <InvertedButton
                            style={{
                                height: 40,
                                width: 40,
                            }}
                            disabled={props.page === 4}
                            onClick={() => {
                                const newPage = props.page + 1 > 4 ? 1 : props.page + 1
                                props.setPage(newPage)
                            }}
                        >
                            <RoundedChevronRightIcon />
                        </InvertedButton>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const TierModalColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const TierModalRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    > div:last-child {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
`

function TierModalBox(props: {
    name: string
    color: string
    price: string
    pricePeriod: string
    icon: string
    features: JSX.Element
    imageFeatures: JSX.Element
    extraFeatures: JSX.Element
    onButtonClick: () => void
    buttonText: string
    value?: string
}): JSX.Element {
    const localization = useLocalization()
    const [extraExpanded, setExtraExpanded] = useState(false)
    return (
        <div
            style={{
                width: '100%',
                padding: '0px 17px 0px',
                maxWidth: 430,
                flex: '1',
                overflowY: 'auto',
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#191B31',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: transparentize(0.88, props.color),
                        padding: 24,
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                    }}
                >
                    {props.value && (
                        <TierValueNew
                            color={props.color}
                            style={{
                                top: 0,
                                justifyContent: 'flex-end',
                                right: 24,
                                left: 'unset',
                            }}
                        >
                            <div>{props.value}</div>
                        </TierValueNew>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 18,
                        }}
                    >
                        <SubIcon
                            icon={props.icon}
                            color={props.color}
                            style={{
                                width: 28,
                                height: 28,
                                margin: 0,
                            }}
                        />
                        <TierName color={props.color}>{props.name}</TierName>
                    </div>
                    <PriceText>
                        <span
                            style={{
                                fontSize: '2.5rem',
                            }}
                        >
                            {props.price}
                        </span>
                        <span
                            style={{
                                fontSize: '1.5rem',
                            }}
                        >
                            {props.pricePeriod}
                        </span>
                    </PriceText>
                    <div
                        style={{
                            borderBottom: `1px solid ${transparentize(0.74, props.color)}`,
                            width: '100%',
                            margin: '24px 0px',
                        }}
                    />{' '}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 16,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <ExtraFeaturesText>{localization.subscription.tierCards.imageGen}</ExtraFeaturesText>
                    </div>
                    <div
                        style={{
                            marginTop: 24,
                        }}
                    >
                        {props.imageFeatures}
                    </div>
                    <div
                        style={{
                            borderBottom: `1px solid ${transparentize(0.74, props.color)}`,
                            width: '100%',
                            margin: '24px 0px',
                        }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 16,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <ExtraFeaturesText>
                            {localization.subscription.tierCards.textGeneration}
                        </ExtraFeaturesText>
                    </div>
                    <div
                        style={{
                            marginTop: 24,
                        }}
                    >
                        {props.features}
                    </div>
                    <div
                        style={{
                            borderBottom: `1px solid ${transparentize(0.74, props.color)}`,
                            width: '100%',
                            margin: '24px 0px',
                        }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 16,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <ExtraFeaturesText>
                            {localization.subscription.tierCards.extraFeatures}
                        </ExtraFeaturesText>
                        <SubtleButton
                            onClick={() => setExtraExpanded(!extraExpanded)}
                            style={{
                                height: 18,
                                width: 18,
                            }}
                        >
                            {extraExpanded ? <RoundedChevronUpIcon /> : <RoundedChevronDownIcon />}
                        </SubtleButton>
                    </div>
                    {extraExpanded && (
                        <div
                            style={{
                                marginTop: 24,
                            }}
                        >
                            {props.extraFeatures}
                        </div>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flex: '1',
                        }}
                    />
                    <TierCtaButton
                        color={props.color}
                        onClick={props.onButtonClick}
                        style={{
                            width: '100%',
                            marginTop: 24,
                        }}
                    >
                        {props.buttonText}
                    </TierCtaButton>
                </div>
            </div>
        </div>
    )
}

const ExtraFeaturesText = styled.div`
    font-size: 1.125rem;
    font-weight: 700;
    font-family: ${(props) => props.theme.fonts.headings};
`

export const TierCtaButton = styled.a<{ color: string }>`
    cursor: pointer;
    left: calc(25px);
    border: 2px solid ${(props) => props.color};
    background-color: ${(props) => props.theme.colors.bg3};
    padding: 12px 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    > div {
        background-color: ${(props) => props.color};
    }

    :hover {
        color: ${(props) => props.theme.colors.bg3};
        background-color: ${(props) => props.color};
        > div {
            background-color: ${(props) => props.theme.colors.bg3};
        }
    }
    font-weight: 700;
`

const UnsubscribeModalContainer = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.colors.bg1};
    padding: 50px 30px 30px 30px;
    display: flex;
    max-width: 400px;
    width: 100vw;
    flex-direction: column;
    gap: 24px;
`
